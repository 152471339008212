/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-05-10
 * 
 * Description: 
 * Class to facilitate the creation and manipulation of 
 * sessions in the Petu App
 * 
 */

import { _st } from '../softech';
import { API } from '@/inc/api';
import { SessionType } from '../models';

export class PetuSession {
    constructor(session = null) {
        if( _st.isNU( session )) {
            this.data = {
                id          : '',
                name        : '',
                customerId  : '',
                type        : SessionType.PRESENCIAL.toLowerCase(),
                dateCreated : null
            }
        }
        else {
            this.data = session;
        }
    }

    async load( sessionId = null ) {
        if( _st.isEmpty( sessionId ) ) {
            console.error('Session ID cannot be empty');
            return;
        }

        // load session from DB
        try {
            let api = new API();
            let res = await api.get(`/session/${sessionId}`);

            if( res.data.status !== true ) {
                return Promise.reject( res.data.message );
            }

            this.data = res.data.data;
            return Promise.resolve();
        }
        catch(error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    async getList({ from, to, type, limit, dateCreated }) {
        try {
            let api = new API();

            let args = btoa( JSON.stringify({ from, to, type, limit, dateCreated }) );
            let res = await api.get(`/session/list/${args}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return res.data.data;
        } 
        catch(error) {
            return Promise.reject( error );
        }
    }

    async save() {
        delete this.data.checkIn;
        delete this.data.createdAt;
        delete this.data.updatedAt;

        try {
            let api = new API();

            let res = await api.post( `/session`, this.data );

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }
            
            this.data = res.data.data;
        }
        catch(error) {
            return Promise.reject( error );
        }

        // if( _st.isNU( this.data.id ) || _st.isEmpty( this.data.id ) ) {
        //     this.data.dateCreated = now
        //     this.data.id = uuidv4()
            
        //     const c = await API.graphql(graphqlOperation(createSession, { input: this.data }));
        //     this.data = c.data.createSession;
        // }
        // else {
        //     await API.graphql(graphqlOperation(updateSession, { input: this.data }));
        // }
    }
}