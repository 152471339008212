<template>
    <div>
        <canvas id="qrCanvas"></canvas>
    </div>
</template>

<script>
import QRCode from 'qrcode';
import { _st } from '@/softech';
export default {
    props: {
        text: { type: String, default: '' }
    },
    data() {
        return {
            canvas: null,
        }
    },
    mounted() {
        this.canvas = document.getElementById('qrCanvas');
        this.generateQRCode();
    },
    watch: {
        text(v) {
            this.generateQRCode();
        }
    },
    methods: {
        generateQRCode() {
            if( _st.isNUE( this.text ) )
                return; 

            QRCode.toCanvas(this.canvas, this.text, { width: 300, color: { dark: "#639d43" } }, function (error) {
                if (error) 
                    console.error(error);

                console.log('success!');
            });
        }
    }
}
</script>

<style lang="scss" scoped>
#qrCanvas {
    width: 100%;
    height: 100%;
}
</style>